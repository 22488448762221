<template>
  <v-app-bar app>
    <v-spacer></v-spacer>
     <!-- Logo para telas grandes -->
    <v-img
      :src= logos.logo4
      style="transform: scale(10)"
      v-if="telaMediaGrande"
    ></v-img>
    <!-- Logo para telas pequenas -->
    <v-spacer></v-spacer>
    <v-img
      :src="logos.logo4"
      style="transform: scale(8)"
      v-if="!telaMediaGrande"
    ></v-img>
    <v-spacer></v-spacer>
    <template v-slot:prepend v-if="!telaMediaGrande">
      <v-app-bar-nav-icon @click="dialog = true"></v-app-bar-nav-icon>
    </template>
    <template v-if="telaMediaGrande">
      <v-tabs v-model="tab">
        <v-tab base-color="var(--cor1)" color="var(--cor1)" to="/">Home</v-tab>
        <v-tab base-color="var(--cor1)" color="var(--cor1)" to="/servicos">Serviços</v-tab>
        <v-tab base-color="var(--cor1)" color="var(--cor1)" to="/sobre">Sobre</v-tab>
      </v-tabs>
    </template>
    <v-spacer></v-spacer>
    <!--<v-btn color="var(--cor1)" @click="dialogWhatsapp = !dialogWhatsapp" v-if="telaMediaGrande">-->
    <v-btn color="var(--cor1)" :href="`https://wa.me/${whatsApp}`" v-if="telaMediaGrande">
      <v-icon size="24">mdi-whatsapp</v-icon>
      <span class="white--text ml-2">{{ empresa.telefone }}</span>
    </v-btn>
    
  <!--<whats-app-mensagens v-if="dialogWhatsapp" @close="dialogWhatsapp = false" />-->
  <v-spacer v-if="telaMediaGrande"></v-spacer>
  </v-app-bar>

  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar flat>
        <v-btn icon @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Menu</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-list>
        <v-list-item to="/" @click="dialog = false">
          <v-list-item-title>Home</v-list-item-title>
        </v-list-item>
        <v-list-item to="/servicos" @click="dialog = false">
          <v-list-item-title>Serviços</v-list-item-title>
        </v-list-item>
        <v-list-item to="/sobre" @click="dialog = false">
          <v-list-item-title>Sobre</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-card>
  </v-dialog>
</template>

<script>
import { useDisplay } from "vuetify";
//import WhatsAppMensagens from '@/components/contato/WhatsAppMensagens.vue';
import { mapGetters, mapState } from 'vuex';
export default {
  data: () => ({
    tab: null,
    telaMediaGrande: false,
    dialog: false,
    dialogWhatsapp: false
  }),
  computed: {
    ...mapState(['empresa']),
    ...mapGetters(['getLogos', 'getWhatsapp']),

    whatsApp() {
      return this.getWhatsapp
    },
    logos() {
      return this.getLogos
    }
  },
  components: {
    //WhatsAppMensagens,
  },
  mounted() {
    const { mdAndUp } = useDisplay();
    this.telaMediaGrande = mdAndUp.value;
  },
};
</script>
