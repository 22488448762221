<template>
  <v-app>
    <app-bar />
    <v-main>
      <router-view />
    </v-main>
    <v-fab icon="mdi-whatsapp" app location="bottom" color="green" :href="`https://wa.me/${whatsApp}`"></v-fab>
    <!--<v-fab icon="mdi-whatsapp" app location="bottom" color="green" @click="dialogWhatsapp = !dialogWhatsapp"></v-fab>
    <whats-app-mensagens v-if="dialogWhatsapp" @close="dialogWhatsapp = false" />-->
  </v-app>
</template>

<script>
import AppBar from "@/components/home/AppBar.vue";
//import WhatsAppMensagens from './components/contato/WhatsAppMensagens.vue';
import { mapGetters } from 'vuex';
export default {
  data: () => ({
    //dialogWhatsapp: false
  }),
  components: {
    AppBar,
    //WhatsAppMensagens,
  },
  computed: {
    ...mapGetters(['getPaletaCores', 'getWhatsapp']),

    cores() {
      return this.getPaletaCores
    },
    whatsApp() {
      return this.getWhatsapp
    }
  }
};
</script>
