import { createStore } from 'vuex'

export default createStore({
  state: {
    reviews: [
      {
        id: 1,
        name: "Gio",
        text: "Lugar que amo! Sempre muito bem recebida, não fico sem😍",
        avatar: require("@/assets/avaliacoes/gio.png")
      },
      {
        id: 2,
        name: "Flaviani Boien Correa",
        text: "Profissional maravilhosa e muito querida! Desde que cheguei em Sinop faço meus cílios e sobrancelhas com ela. Super indico!",
        avatar: require("@/assets/avaliacoes/flaviane.png")
      },
      {
        id: 3,
        name: "Brenda Barbosa Macedo Ferreira",
        text: "Incrível, desde o acolhimento até os procedimentos impecáveis!! Ótima profissional.",
        avatar: require("@/assets/avaliacoes/brenda.png")
      },
      {
        id: 4,
        name: "Izabella Merlo",
        text: "Amei muito o atendimento é impecável e o procedimento também amei demais quase dormi de tão relaxante.",
        avatar: require("@/assets/avaliacoes/izabela.png")
      },
      {
        id: 5,
        name: "Nicole Bertuci Malagutti",
        text: "Fiz o cílios e a boca. Dura muito e fica lindoooo e super natural. Amei demais💗💗💗",
        avatar: require("@/assets/avaliacoes/nicole.png")
      },
      {
        id: 6,
        name: "Gabrieli Zachow",
        text: "Muitooo boaa, a Lavínia é super atenciosa, os procedimentos são incríveis!!!",
        avatar: require("@/assets/avaliacoes/gabrieli.png")
      }
    ],
    faqs: [
      { pergunta: 'Quanto tempo dura o efeito do Lash Lifting?', resposta: 'O Lash Lifting dura de 45 a 60 dias dependendo do ciclo de crescimento dos seus cílios.'},
      { pergunta: 'O Lash Lifting danifica os cílios naturais?', resposta: 'Não, o Lash Lifting é um procedimento seguro que não danifica os cílios naturais quando realizado por um profissional qualificado.'},
      { pergunta: 'É necessário algum cuidado especial após o Lash Lifting?', resposta: 'Sim, é recomendado evitar molhar os cílios nas primeiras 24 horas após o procedimento. Evite também produtos à base de óleo ou álcool, como removedores de maquiagem. Após o lash lifting, não utilize rímel nas primeiras 48 horas.'},
      { pergunta: 'Quanto tempo dura o efeito do Brow Lamination?', resposta: 'O efeito do Brow Lamination dura de 30 a 45 dias'},
      { pergunta: 'É necessário algum cuidado especial após o Brow Lamination?', resposta: 'Sim, é recomendado evitar molhar as sobrancelhas nas primeiras 24 horas após o procedimento e evitar produtos oleosos.'},
      { pergunta: 'Quanto tempo dura o efeito do Pump Lips?', resposta: 'O efeito de uma sessão de Pump Lips dura em torno de 15 a 20 dias. São necessárias mais sessões para um resultado mais duradouro, caso a cliente deseje.'},
      { pergunta: 'O procedimento de Pump Lips é doloroso?', resposta: 'Todos os procedimentos, exceto design e epilação na linha, são indolores.'},
    ],
    combos: [
      require('@/assets/imgs/servicos/promocoes/combo_1.svg'),
    ],
    empresa: {
      comoFunciona: [
        {
          icone: 'mdi-calendar-check',
          titulo: 'Agendamento',
          texto: 'Todos os atendimentos precisam ser marcados antecipadamente.',
        },
        {
          icone: 'mdi-spa',
          titulo: 'Experiência',
          texto: `A profissional Lavínia realizará o procedimento escolhido com muito cuidado e atenção, garantindo um resultado lindo e satisfatório. Para uma experiência de beleza completa, experimente nosso delicioso cappuccino com Nutella.`,
        },
        {
          icone: 'mdi-heart-outline',
          titulo: 'Satisfação',
          texto: 'Nossa prioridade é sua satisfação. Estamos comprometidos em superar suas expectativas e garantir que você se sinta valorizada e confiante',
        }
      ],
      /*historia: [
        {
          ano: "2020",
          titulo: "O Início de um Sonho - 'Lavínia Beauty Studio'",
          texto: "Fiz meu primeiro curso de design de sobrancelhas. Naquela época, ainda não enxergava o potencial que tinha e, por isso, atendi apenas família e amigos mais próximos. Parei de atender por um período de dois anos devido à gravidez e ao nascimento da minha filha."
        },
        {
          ano: "2022",
          titulo: "A Paixão Transforma-se em Profissão - 'Lavínia Beauty Studio'",
          texto: "Decidi que queria realmente atuar na área da beleza e transformar isso na minha profissão. No final de 2022, a empresa foi criada. No entanto, muito antes, a paixão pela área da beleza já havia nascido dentro do meu coração."
        },
        {
          ano: "2024",
          titulo: "A Metamorfose - 'Lavínia Peixoto Beauty'",
          texto: "Quis repaginar a identidade da minha marca, e agora somos 'Lavínia Peixoto Beauty'. Depois de alguns perrengues, tropeços e de me mudar quatro vezes de endereço, sinto-me realizada e grata por estar trabalhando com o que amo e renovando a autoestima de mulheres maravilhosas!"
        }
      ],
      missao: { 
        texto:"Oferecer mais do que procedimentos estéticos, proporcionando experiências que combinem beleza, autocuidado e bem-estar, com um atendimento personalizado e enriquecedor.",
        imagem: require('@/assets/imgs/modelos/modelo_5.jpg')
      },
      valores: [
        {
          icone: "mdi-butterfly",
          titulo: "Resiliência e Transformação",
          descricao: "Inspirados na metamorfose das borboletas, valorizamos a capacidade de superar desafios e promover mudanças positivas.",
        },
        {
          icone: "mdi-leaf",
          titulo: "Aprimoramento Contínuo",
          descricao: " Comprometidos com a evolução constante, buscamos sempre melhorar nossos serviços e práticas, fundamentados na fé e esperança.",
        },
        {
          icone: "mdi-account-group",
          titulo: "Atendimento Personalizado",
          descricao: " Acreditamos que cada cliente merece um tratamento único e enriquecedor, adaptado às suas necessidades e expectativas individuais, proporcionando uma experiência excepcional.",
        },
        {
          icone: "mdi-star",
          titulo: "Excelência e Profissionalismo",
          descricao: "Nos dedicamos a manter os mais altos padrões de qualidade e profissionalismo em todos os aspectos do nosso trabalho, garantindo resultados excepcionais e satisfação total dos nossos clientes.",
        },
      ],*/
      cores: {
        cor1: "#C76883",
        cor2: "#B59694",
        cor3: "#D4BCB8",
        cor4: "#7A7A7A",
        cor5: "#EFECE8"
      },
      logos: {
        logo1: require('@/assets/logos/Ícone Positivo.png'),
        logo2: require('@/assets/logos/Logotipo Alternativo Positivo.png'),
        logo3: require('@/assets/logos/Logotipo Alternativo2 Positivo.png'),
        logo4: require('@/assets/logos/Logotipo Horizontal Positivo.svg'),
        logo5: require('@/assets/logos/Logotipo Vertical 4.png'),
      },
      nome: 'Lavínia Peixoto Beauty',
      telefone: '(66) 9 9626-3334',
      whatsapp: '+5566996263334',
      whatsappMensagens: [
        { texto: 'Olá, gostaria de mais informações.' },
        { texto: 'Estou interessado nos serviços da sua empresa.' },
        { texto: 'Tenho uma pergunta sobre um procedimento.' }
      ],
      email: 'laviniabeautystudio.contato@gmail.com',
      endereco: { 
        rua: 'Av. das Itaúbas', 
        numero: '1893 - Ap.03', 
        bairro: 'Jardim Paraiso',
        localizacao: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3904.5323309613045!2d-55.51678892494179!3d-11.867973988355086!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x93a77f6c0b5cee73%3A0x1a483cc94a930e77!2sLavinia%20Beauty%20Studio!5e0!3m2!1spt-BR!2sbr!4v1721662511324!5m2!1spt-BR!2sbr'
      },
      redesSociais: {
        instagram: 'https://www.instagram.com/lashlifting.sinop/',
        facebook: 'https://www.facebook.com/profile.php?id=100092090378803&locale=pt_BR'
      }
    },
    servicos: [
      {
        id: 1,
        nome: 'Lash Lifting',
        descricao: 'O Lash Lifting é um procedimento que curva, tinge e nutre os cílios naturais, proporcionando um efeito de levantamento que faz com que o olhar pareça mais jovem e realçado.',
        valor: 'R$ 150,00',
        vantagens: [
          'Duração de 45 a 60 dias',
          'Não necessita de manutenção',
          'Resultado natural'
        ],
        capa: require('@/assets/imgs/servicos/lash_lifting/capa2_lash_lifting.jpg'),
        imagens: [
          { antes: require("@/assets/imgs/servicos/lash_lifting/1_lash_lifting_antes.jpg"), depois: require("@/assets/imgs/servicos/lash_lifting/1_lash_lifting_depois.jpg") },
        ]
      },
      {
        id: 2,
        nome: 'Brow Lamination',
        descricao: 'A Brow Lamination é um procedimento que alinha as sobrancelhas, dando mais volume e definição, com a opção de tintura.',
        valor: 'R$ 120,00',
        vantagens: [
          'Duração de 30 a 45 dias',
          'Cobre falhas',
          'Resultado natural',
          'Não necessita de manutenção'
        ],
        capa: require('@/assets/imgs/servicos/brow_lamination/capa_brow_lamination.jpg'),
        imagens: [
          { antes: require("@/assets/imgs/servicos/brow_lamination/1_brow_lamination_antes.jpg"), depois: require("@/assets/imgs/servicos/brow_lamination/1_brow_lamination_depois.jpg") },
          { antes: require("@/assets/imgs/servicos/brow_lamination/2_brow_lamination_antes.jpg"), depois: require("@/assets/imgs/servicos/brow_lamination/2_brow_lamination_depois.jpg") },
        ]
      },
      {
        id: 3,
        nome: 'Epilação Buço',
        descricao: 'Epilação no Buço faz a retirada pela raiz dos pelos. Para esse procedimento utilizamos linha ou pinça.',
        valor: 'R$ 15,00',
        vantagens: [
          'Crescimento lento',
          'Pele lisinha',
          'Afinamento dos pelos'
        ],
        capa: require('@/assets/imgs/servicos/epilacao_buco/capa_epilacao_buco.jpg'),
        imagens: [
          { antes: require("@/assets/imgs/servicos/epilacao_buco/1_epilacao_buco_antes.jpg"), depois: require("@/assets/imgs/servicos/epilacao_buco/1_epilacao_buco_depois.jpg") },
        ]
      },
      {
        id: 4,
        nome: 'Design com Tintura',
        descricao: 'O Design Personalizado com aplicação de Tintura, faz a retirada do excesso de pelos e tinge os pelos das sobrancelhas naturalmente',
        valor: 'R$ 60,00',
        vantagens: [
          'Preenche falhas',
          'Sobrancelhas mais marcantes',
          'Resultado natural e duradouro'
        ],
        capa: require('@/assets/imgs/servicos/design_tintura/capa_design_tintura.jpg'),
        imagens: []
      },
      {
        id: 5,
        nome: 'Design Personalizado',
        descricao: 'O Design Personalizado de sobrancelhas faz a retirada apenas do excesso de pelos, mantendo o formato natural da cliente.',
        valor: 'R$ 45,00',
        vantagens: [
          'Sobrancelhas harmônicas',
          'Sensação de limpeza',
          'Crescimento lento'
        ],
        capa: require('@/assets/imgs/servicos/design_personalizado/capa_design_personalizado.jpg'),
        imagens: [
          { antes: require("@/assets/imgs/servicos/design_personalizado/1_design_personalizado_antes.jpg"), depois: require("@/assets/imgs/servicos/design_personalizado/1_design_personalizado_depois.jpg") },
        ]
      }
      // Adicione mais procedimentos conforme necessário
    ]
  },
  getters: {
    getServico: (state) => (id) => {
      const servico = state.servicos.find(servico => servico.id === id);
      return servico ? servico : [];
    },
    getLogos: (state) => state.empresa.logos,
    getValores: (state) => state.empresa.valores,
    getMissao: (state) => state.empresa.missao,
    //getHistoria: (state) => state.empresa.historia,
    getComoFunciona: (state) => state.empresa.comoFunciona,
    getWhatsapp: (state) => state.empresa.whatsapp
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
