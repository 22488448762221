import { createApp } from 'vue'

// Icons
import '@mdi/font/css/materialdesignicons.css'

// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

// Root
import '@/assets/css/root.css';
// Fontes
import '@/assets/css/fontes.css';
// Classes CSS
import '@/assets/css/classes.css'
// Elementos 
import '@/assets/css/elementos.css'
// Media
import '@/assets/css/media.css'

import App from './App.vue'
import router from './router'
import store from './store'

const vuetify = createVuetify({
    components,
    directives,
})

createApp(App).use(vuetify).use(store).use(router).mount('#app')
